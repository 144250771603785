<template>

	<div class="container">

		<div class="row">
			<div class="col-10">
				<v-form ref="form" class="brand-form">
					<v-autocomplete
					v-model="assignedUsers"
					:items="users"
					outlined
					class="mt-10"
					label="Search for Users or Groups"
					multiple

				></v-autocomplete>
				</v-form>
			</div>
		</div>

		<div class="col-10">
			<div class="row justify-content-center align-items-center">
				<div class="col text-right">
					<v-btn
						class="admin-primary-button primary-contrast-background shadow-none"
						:disabled="true"
						>Assign</v-btn
					>
				</div>
			</div>
		</div>

	</div>

	<!-- <v-container class="main-container">
		<v-row>
			<v-col cols="8">
				<AdminDefaultSubheader>
					Users and groups assigned for this course
				</AdminDefaultSubheader>

				<v-autocomplete
					v-model="assignedUsers"
					:items="users"
					outlined
					class="mt-10"
					label="Search for Users or Groups"
					multiple
					:disabled="true"
				></v-autocomplete>

				<v-btn
					class="admin-primary-button primary-contrast-background"
					:disabled="true"
					>Assign</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
export default {
	name: "CourseSettingsAssign",
	components: {
		AdminDefaultSubheader
	},
	data() {
		return {
			users: ["Wojciech Borys", "Bartosz Sroka", "Antony Peiris"],
			assignedUsers: []
		};
	}
};
</script>

<style></style>
